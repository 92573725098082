import { Location }              from '@angular/common';
import { Component }             from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
}                                 from '@angular/forms';
import {
    ActivatedRoute,
    ParamMap
}                                 from '@angular/router';

import { BaseComponent }          from '@Base/';
import { MessageService }         from '@Messaging/';
import { 
    ElementService,
    ProgressSpinnerDialogService
}                                 from '@Misc/Services';
import {
    DeviceGroup,
    Element,
    ElementCommon as ElementC
}                                 from '@ObjElements/';

import { DeviceGroupService }     from './device-group.service';


enum ButtonType {
    Cancel,
    Save
}; // enum


@Component({
    selector:     'qp-devicegroup-add',
    templateUrl:  'device-group-add.component.html',
    styleUrls:   ['device-group-add.component.css'],
    providers:   [ DeviceGroupService ] // scope services to this component only; need to this here for ElementsService
})
export class DeviceGroupAddComponent extends BaseComponent
{
    public readonly name:      string                  = "Add Device Group";

    private         _id:       number                  = -1;
    public          data:      DeviceGroup | undefined;

    // public form: FormGroup                             = new FormGroup({
    //     name:        new FormControl(''),
    //     description: new FormControl(''),
    // }); // FormGroup

    public form: FormGroup;
    

    constructor(private readonly ActRoute:                     ActivatedRoute,
                                 FormBuilder:                  FormBuilder,
                private readonly Location:                     Location,

                private readonly DeviceGroupService:           DeviceGroupService,
                private readonly MessageService:               MessageService,
                private readonly ProgressSpinnerDialogService: ProgressSpinnerDialogService)
    {
        super();

        this.form = FormBuilder.group({
            name:        ['', Validators.required],
            description: ''
        }); // group
    }


    public get ButtonType(): typeof ButtonType
    {
        return ButtonType;
    }


    //
    // Public methods
    //
    public onClick(button: ButtonType, event: MouseEvent): void
    {
        switch (button) {
            case ButtonType.Cancel:
                this.Location.back();
            break;
        } // switch
    }


    public onSubmit()
    {
        // TODO: Use EventEmitter with form value
        console.warn(this.form.value);

        const _attrs  = {
            // _description: 'description',
            // _name:        'name',
            [DeviceGroup.attrs._description as string]: 'description',
            [DeviceGroup.attrs._name        as string]: 'name'
        }; // _attrs
        console.warn(_attrs);

        // Create DeviceGroup
        const dg = DeviceGroup.get(this.form.value, _attrs);
        console.error(dg)
        console.error(DeviceGroup.attrs._name)
        console.error(DeviceGroup.attrs)

        // Will trigger messge to server
        if (dg instanceof DeviceGroup) this.DeviceGroupService.set(
            this.MessageService.messages.msgTypesAction.add,
            dg
        ); // sendMsgSet
    }


    public button(type?: ButtonType): void
    {
        let cmd: string | undefined = undefined;
        // switch (type) {
        //     case ButtonType.play:
        //         cmd = this.MessageService.messages.msgTypesAction.start;
        //         break;

        //     case ButtonType.stop:
        //         cmd = this.MessageService.messages.msgTypesAction.stop;
        //         break;

        //     default:
        //         // Do nothing
        // } // switch

        if (cmd) {
            // // Will trigger messge to server
            // this.DeviceGroupService.set(
            //     cmd,
            //     cmd === this.MessageService.messages.msgTypesAction.stop
            //         ? this.data?.instanceid
            //         : this._id
            // ); // sendMsgSet
        }
    }


    public refresh(id?: number): void
    {
        const idL: number = id ? id : this._id;
        // if (idL >= 0) this.DeviceGroupService.id = idL; // triggers data look-up
    }


    public select(d: any): any
    {
        console.error(d)
    }


    //
    // Protected functions
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising DeviceGroupAdd component");

        // this.sub = this.favoriteColorControl.valueChanges.subscribe((d: any) => {
        //     console.error(d);
        // }); // valueChanges

        
        // this.ProgressSpinnerDialogService.show(true);
        // this.sub = this.ActRoute.paramMap.subscribe((params: ParamMap): void => {
        //     // this.sub = this.ElementService.added.subscribe((data: Element | undefined): void => {
        //     //     this.data = DeviceGroup.get(data);
        //     //     this.ProgressSpinnerDialogService.clear();
        //     // }); // subscribe()

        //     const s: string | null = params?.get("id");
        //     if (! isNaN(Number(s)) ) this.refresh(this._id = Number(s));

        //     // this.sub = this.DeviceGroupService.updated$.subscribe((data: Element | ElementC | undefined): void => {
        //     //     this.data = DeviceGroup.get(data);
        //     //     // console.error(this.data)
        //     //     this.ProgressSpinnerDialogService.clear();
        //     // }); // subscribe()

        // }); // subscribe()
    }
}