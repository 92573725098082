<div class="content">
    <!-- <a [routerLink]="['..']"><h4>Back to surveys list</h4></a> -->
    <!-- <qp-route-back [route]="['..']"></qp-route-back> -->

    <qp-window-header
        [title]="name" 
        [value]="data?.name ? (data?.name | titlecase) : data?.id"
        [routeBack]="['..']"

        (refresh)="refresh()">
    </qp-window-header>

    <!-- <input type="text" [formControl]="favoriteColorControl"> -->

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div>
            <label for="name">Name </label>
            <input id="name" type="text" formControlName="name">
        </div>
        <div>
            <label for="description">Description </label>
            <input id="description" type="text" formControlName="description">
        </div>

        <button type="submit" [disabled]="! form.valid">Save</button>
        
        <button (click)="onClick(ButtonType.Cancel, $event)">Cancel</button>
    </form>

    <!-- <qp-survey-info [data]=data></qp-survey-info> -->
    <div class="content2" [hidden]="! data"> 
        <!-- <qp-element-info-container (selected)="select($event)" fxFlex="auto" class="content2" -->
        <!-- <qp-device-group-info (selected)="select($event)" [showName]="false" fxFlex="auto" class="content2"
            [data]="data"
        >
        </qp-device-group-info> -->
        <!-- </qp-element-info-container> -->
    </div>

</div>