import {
    Component,
    Host,
    HostBinding,
    ViewChild
}                                 from '@angular/core';
// import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatRow }                 from '@angular/material/table';
import { MatDrawer }              from '@angular/material/sidenav';


import {
    ActivatedRoute,
    NavigationEnd,
    NavigationError,
    Router 
}                                 from '@angular/router';

import {
    Observable
    // Subscription,
    
    // filter,
    // take
}                                 from 'rxjs';


import {
    ListDataAttributes,
    ListDataComponent
}                                 from '@GuiElements/';
import { IconsMaterial }          from '@Icons/';
import {
    DataServiceEvents,
    // DataServiceI,
    ProgressSpinnerDialogService
}                                 from '@Misc/Services/';
import {
    DeviceGroup,
    ObjectElementsComponent
}                                 from '@ObjElements/';

import { routeNames }             from '../app.routing.names';
import { DeviceGroupsService }    from './device-groups.service'; 


enum ButtonType {
    Add,
    Close
}; // enum


@Component({
    selector:      'qp-devicegroups',
    templateUrl:   'device-groups.component.html',
    styleUrls:   [ 'device-groups.component.css', '../Misc/Components/list.component.css' ]
})
export class DeviceGroupsComponent extends ObjectElementsComponent
{
    public         readonly iconClose:      string               = IconsMaterial.close;
    
    // private static readonly _sideNavClose:  string               = "close";
    // private static readonly _sideNavOpen:   string               = "open";
    // private static readonly _sideNavToggle: string               = "close";
    private static readonly _title:         string               = "Device Groups";

    @HostBinding('class')
    private        readonly _cls:           Host                 = 'fill2';

    @ViewChild('devicegroupssidenav', {static: true})
    private                 _sidenav:       MatDrawer

    public         readonly attrs:          ListDataAttributes[] =
    [
        // {name: ListDataComponent.statusAttr, alias: ' '},
        {name: 'id',                         alias: 'id'},
        {name: 'name',                       alias: 'name'},
        {name: 'type',                       alias: 'type'},
        {name: 'description',                alias: 'description'},
    ]; // attrs
    public        readonly  attrOrder:      string               = 'id';//'start';
    public        readonly  attrOrderDesc:  boolean              = true;


    // Set to undefined to use normal nav, not drawer
    public static readonly  outlet:         string | undefined   = undefined;//"devicegroup";

    public        readonly  drawer:         string | undefined   = DeviceGroupsComponent.outlet;

    public                  vals:           DeviceGroup[] | undefined = undefined;//[];


    public constructor(private readonly ActRoute:                     ActivatedRoute,
                       private readonly Router:                       Router,

                                        DeviceGroupsService:          DeviceGroupsService,
                                        ProgressSpinnerDialogService: ProgressSpinnerDialogService)
    {
        super(DeviceGroupsService, ProgressSpinnerDialogService);

        this.sub = this.Router.events.subscribe((e) => {
            if      (e instanceof NavigationEnd)   this.open();
            else if (e instanceof NavigationError) this.close()
        }); // subscribe()

        // const obs: Observable<any> | undefined = DevceGroupsService[DataServiceEvents.data];
        // if (obs instanceof Observable) this.sub = obs.subscribe((d: Survey[]): void => {
        //     if (Array.isArray(d)) {
        //         this.vals.length = 0;
        //         this.vals = d.map(o => Object.fromEntries(this.attrs.map(k => [k, o[k]])) as Survey); // Filter by selected attriutes
        //     }
        // }); // subscribe()
    }


    //
    // Getters
    //
    public get ButtonType(): typeof ButtonType
    {
        return ButtonType;
    }


    public static get titleS(): string
    {
        return DeviceGroupsComponent._title;
    }

    public get title(): string
    {
        return DeviceGroupsComponent.titleS;
    }


    //
    // Public methods
    //
    public onClick(button: ButtonType, event: MouseEvent): void
    {
        switch (button) {
            case ButtonType.Add:
                this.Router.navigate(
                    [ routeNames.devicegroups, 'add'],
                    { 
                        state: { parent: this.Router.url } 
                    }
                ) // navigate()
            break;

            case ButtonType.Close:
                this.close();
            break;
        } // switch
    }


    // Override
    public override select(row: MatRow): Promise<boolean> | undefined
    {
        super.select(row);
       
        const rowS: DeviceGroup | undefined = row ? row as DeviceGroup : undefined;
        if (rowS && rowS.id) {
            // if (! this.sidenav.opened) this.sidenav.open()
            const ret: Promise<boolean> = this.Router.navigate(
                DeviceGroupsComponent.outlet
                    ? [ {outlets: {
                        [DeviceGroupsComponent.outlet]: [rowS.id ]
                    }} ]

                    : [ rowS.id ],
                    
                { relativeTo: this.ActRoute.parent }
            ) // navigate()
            ret.then((r: boolean) => {
                // if (! r) this.toggle()
            }); // then()
            return ret;
        }
        else return undefined;
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(full: boolean = false): void
    {
        super.cleanUp();
        // super.cleanUp(true); // don't blank service in this case

        this.close();
    }


    //
    // Private methods
    //
    // private check(fn: string): boolean
    // {
    //     return (this._sidenav instanceof MatDrawer && !!fn && this._sidenav[fn] instanceof Function);
    // }


    private close(): void
    {
        // if (this.check(DeviceGroupsComponent._sideNavClose) && this._sidenav.opened) this._sidenav.close();
        if (this._sidenav instanceof MatDrawer
            && this._sidenav.close instanceof Function
            && this._sidenav.opened) this._sidenav.close();
    }


    private open(): void
    {
        if (this._sidenav instanceof MatDrawer
            && this._sidenav.open instanceof Function
            && ! this._sidenav.opened) this._sidenav.open();
        // if (this.check(DeviceGroupsComponent._sideNavOpen) && ! this._sidenav.opened) this._sidenav.open();
    }


    private toggle(): void
    {
        if (this._sidenav instanceof MatDrawer
            && this._sidenav.toggle instanceof Function) this._sidenav.toggle();
        // if (this.check(DeviceGroupsComponent._sideNavToggle)) this._sidenav.toggle();
    }
}