import { 
    Component,
    EventEmitter,
    Input,
    Output
}                                  from '@angular/core';
import { MatRow }                  from '@angular/material/table';
import {
    ActivatedRoute,
    ParamMap,
    Router 
}                                  from '@angular/router';
import { Observable }              from 'rxjs';

import { BaseComponent }           from '@Base/';
import { ListDataComponent }       from '@GuiElements/';
import { IconsMaterial }           from '@Icons/';
import {
    DataServiceEvents,
    // DataServiceI,
    ProgressSpinnerDialogService
}                                  from '@Misc/Services/';
import {
    ObjectElementsComponent,
    Device
}                                  from '@ObjElements/';

import { DevicesService }          from './devices.service'; 

import { routeNames }              from '../app.routing.names';


@Component({
   selector:      'qp-devices',
   templateUrl:   'devices.component.html',
   styleUrls:   [ 'devices.component.css', '../Misc/Components/list.component.css' ],
   providers:   [ DevicesService ]
})
export class DevicesComponent extends ObjectElementsComponent
{
    public readonly icon:          string                 = IconsMaterial.devices;
    public readonly title:         string                 = "Devices";

    public readonly onlineParam:   string                 = "online";

    public          devices:       Device[];

    // Note: no specific device attrs passed in; uses attrs select by getDevicesForSite() db query
    // optionally passed in from parent HTML
    @Input()
    public          attrs:         string[]               = [
        ListDataComponent.stateAttr,
        'id',
        'type',
        'description'
        // 'status'
    ]; // attrs

    public readonly attrOrder:     string                 = 'name';

    @Input()
    public          data:          Device[] | undefined   = undefined;

    @Input()
    public          parentId:      number;

    @Input()
    public          searchEnabled: boolean                = false;

    @Input()
    public          selectEnabled: boolean                = false;

    @Input()
    public          showTitle:     boolean                = true;

    @Output()
    public readonly selectedM:     EventEmitter<Device[]> = new EventEmitter();


    constructor(private readonly ActRoute:    ActivatedRoute,
                private readonly Router:      Router,
                
                DevicesService:               DevicesService,
                ProgressSpinnerDialogService: ProgressSpinnerDialogService)
    {
        super(DevicesService, ProgressSpinnerDialogService);
    }


    //
    // Public functions
    //

    // Override
    public override select(row: MatRow): Promise<boolean> | undefined
    {
        //return this.Router.navigate([ routeNames.devices + '/', (row as DeviceI).serialNum ]);
        return row && (row as Device).id
            ? this.Router.navigate(
                [routeNames.devices, (row as Device).id],
                { state: { parent: this.Router.url } }
            )
            
            : undefined;
    }


    public selectM(rows: MatRow[]): void
    {
        this.selectedM.emit(rows as Device[]);
    }


    public setDevices(devs: Device[]): void
    {
        this.devices = devs;
    }


    //
    // Protected methods
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        this.sub = this.ActRoute.queryParamMap
            .subscribe((params: ParamMap): void => {
                const s: boolean = params && params.has(this.onlineParam) ? !! params.get(this.onlineParam) : false;
                // if (s) this.id = s;  // triggers call to refresh();
            }); // subscribe
    }
}