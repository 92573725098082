import { Injectable }      from '@angular/core';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef
 }                         from '@angular/material/dialog';

import { BaseService }     from '@Base/';

import { DialogComponent } from '../Components/';


export { MatDialogRef }    from '@angular/material/dialog';


@Injectable({
    providedIn: 'root'
})
export class DialogService extends BaseService
{
    private                 mDialogRefL:          any    = undefined; // leave as 'any'; closeAll() doesn't exist on MatDialogRef
    private static readonly mDialogHeightDefault: number = 50;
    private static readonly mDialogWidthDefault:  number = 50;
    private static readonly mDialogHeightMin:     string = "20em";
    private static readonly mDialogWidthMin:      string = "20em";


    constructor(private readonly MatDialog: MatDialog)
    {
        super();
    }  


    //
    // Public methods
    //
    public showDialog(component:    any,
                      height?:      number | string,
                      width?:       number | string,
                      data?:        any,
                      disableClose: boolean = false,
                      transparent:  boolean = false): MatDialogRef<any>
    {
        const dialogConfig: MatDialogConfig = {
            autoFocus:         true,
            closeOnNavigation: true, // default
            data:              data ? data : {}, // pass data to dialog
            disableClose:      disableClose, // prevents closure by clicking outside of dialog
            hasBackdrop:       ! transparent,
            height:            (typeof height === "string") ? height : ((height != null) ? height + '%' : 'auto'),
            minHeight:         DialogService.mDialogHeightMin,
            minWidth:          DialogService.mDialogWidthMin,
            panelClass:        [ transparent ? 'dialog-transparent' : 'dialog' ],
            restoreFocus:      true,
            width:             (typeof width === "string") ? width : ((width != null) ? height + '%' : 'auto')
        }; // dialogConfig

        if (! (component instanceof DialogComponent)) {
            dialogConfig.data.component = component;
            component = DialogComponent;
        }
        console.debug(dialogConfig);

        return (this.mDialogRefL = 
            (this.MatDialog && this.MatDialog.open instanceof Function)
                ? this.MatDialog.open(component, dialogConfig)
                : this.mDialogRefL
        ); // return
    }


    public showDialog2(component: any, data? : any): MatDialogRef<any>
    {
        return this.showDialog(
            component,
            DialogService.mDialogHeightDefault,
            DialogService.mDialogWidthDefault,
            data
        ); // return
    }


    //
    // Protected methods
    //
    
    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        if (this.mDialogRefL && this.mDialogRefL.closeAll instanceof Function) this.mDialogRefL.closeAll();
        this.mDialogRefL = undefined;
    }
}