<div class="content">

    <mat-drawer-container
        autosize="true"
        [hasBackdrop]="false"
        >

        <mat-drawer #devicegroupssidenav
            [opened]="false"
            [disableClose]="false"
            [mode]="'over'"
            [position]="'end'"
        >
            <button mat-icon-button class="buttonRight" (click)="onClick(ButtonType.Close, $event)" matTooltip="Close">
                <mat-icon>{{ iconClose }}</mat-icon>
            </button>

            <!-- Can't bind name new feature  n A15+ -->
            <router-outlet name="devicegroup"></router-outlet>
        </mat-drawer>

                
        <mat-drawer-content>
            <button mat-icon-button color="accent" matTooltip="Add device group"
                (click)="onClick(ButtonType.Add, $event)"
            >
                <mat-icon aria-label="Add device group">add</mat-icon>
            </button>
            
            <!-- <button mat-fab color="accent" (click)="onClick(ButtonType.Add, $event)" matTooltip="Add device group">
                <mat-icon aria-label="Add device group">add</mat-icon>
            </button> -->

            <qp-list
                [title]="title"
                [service]="service"
                [attrs]="attrs"
                [order]="attrOrder"
                [pageSize]=10
                [orderDesc]="attrOrderDesc"
                (selected)="select($event)">
            </qp-list>

            <!-- [pageSize]=5 -->
            <!-- <qp-list [vals]="vals" [title]="title" [attrs]="attrs" [service]="service" [order]="attrOrder" [orderDesc]="attrOrderDesc" [pageSize]="10" (selected)="select($event)"></qp-list> -->
        </mat-drawer-content>
    </mat-drawer-container>
</div>