<div class="content">
    <!-- <a [routerLink]="['..']"><h4>Back to surveys list</h4></a> -->
    <!-- <qp-route-back [route]="['..']"></qp-route-back> -->

    <qp-window-header
        [title]="name" 
        [value]="data?.name ? (data?.name | titlecase) : data?.id"
        [routeBack]="['..']"

        (refresh)="refresh()">
    </qp-window-header>


    <!-- <qp-survey-info [data]=data></qp-survey-info> -->
    <div class="content2" [hidden]="! data"> 
        <!-- <qp-element-info-container (selected)="select($event)" fxFlex="auto" class="content2" -->
        <qp-device-group-info (selected)="select($event)" [showName]="false" fxFlex="auto" class="content2"
            [data]="data"
        >
        </qp-device-group-info>
        <!-- </qp-element-info-container> -->
    </div>

    <mat-form-field>
        <mat-label>Test Profile</mat-label>
        <mat-select>
          @for (t of tps; track tps) {
            <mat-option [value]="t.name">{{t.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>

    <mat-toolbar fxLayout="row">
        <button mat-button matTooltip="Run tests"
            (click)="onClick(ButtonType.Play, $event)"
            [disabled]="! data?.online || (data?.isStarted() && ! data?.isFinished())"
        >
            <mat-icon aria-label="Run tests">play_circle</mat-icon>
            Run
        </button>

        <button mat-button matTooltip="Stop tests"
            (click)="onClick(ButtonType.Stop, $event)"
            [disabled]="! data?.isStarted() || data?.isFinished()"
        >
            <mat-icon aria-label="Stop tests">stop_circle</mat-icon>
            Stop
        </button>
    </mat-toolbar>


    <mat-tab-group
        mat-stretch-tabs="false"
        dynamicHeight="true"
        preserveContent="false"
    >
        <mat-tab>
            <ng-template mat-tab-label>
                <!-- <mat-icon class="example-tab-icon">thumb_up</mat-icon> -->
                Devices
            </ng-template>

            <ng-template matTabContent>
                <!-- <button mat-icon-button color="accent" matTooltip="Edit devices"
                    (click)="onClick(ButtonType.Edit, $event)"
                >
                    <mat-icon aria-label="Edit devices">edit</mat-icon>
                </button> -->

                <div fxLayout="row">
                    <button mat-icon-button color="accent" matTooltip="Add devices"
                        (click)="onClick(ButtonType.Add, $event)"
                    >
                        <mat-icon aria-label="Add devices">add</mat-icon>
                    </button>

                    <button mat-icon-button color="accent" matTooltip="Remove devices"
                        (click)="onClick(ButtonType.Remove, $event)"
                        [disabled]="! selected || selected.length <= 0"
                    >
                        <mat-icon aria-label="Remove devices">remove</mat-icon>
                    </button>
                </div>
                
                @if (data?.deviceList.length > 0) {
                    <qp-devices
                        [data]=data?.deviceList
                        [parentId]=+data?.id
                        [selectEnabled]="true"
                        [showTitle]=false

                        (selectedM)="selectM($event)"
                    >
                    </qp-devices>
                }
            </ng-template>
        </mat-tab>

        
        <mat-tab>
            <ng-template mat-tab-label>
                <!-- <mat-icon class="example-tab-icon">thumb_up</mat-icon> -->
                Results
            </ng-template>

            <ng-template matTabContent>
                <qp-devices *ngIf="data?.deviceList.length > 0"
                    [data]=data?.deviceList [parentId]=+data?.id>
                </qp-devices>
            </ng-template>
        </mat-tab>


        @if (mapEnable) {
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">map</mat-icon>
                    View on Map
                </ng-template>

                <ng-template matTabContent>
                    <div class="map">
                        <qp-map [data]="data?.deviceList"></qp-map>
                    </div>
                </ng-template>
            </mat-tab>
        }
    </mat-tab-group>

</div>