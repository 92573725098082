import { Injectable }         from '@angular/core';

import {
    Observable,
    Subscription,
}                             from 'rxjs';

import {
    DeviceCommon as DeviceC,
    DeviceGroup,
    Element,
    ElementCommon as ElementC,
    ElementStatus
}                             from '@ObjElements/';

import {
    // Messages,
    MessageService,
    MessageProcessingService 
}                             from '@Messaging/';
import {
    ElementService,
    // ElementsService
}                             from '@Misc/Services/';
import { DeviceHelper }       from '@Common/';


@Injectable({
    providedIn: null // sceoped to DeviceGroupComponent
})
export class DeviceGroupService extends ElementService
{
    private _subs: Subscription | undefined;

    public constructor(                 MessageProcessingService: MessageProcessingService,
                                        MessageService:           MessageService)

                       // Used to store device information for constituent devices                 
                    //    private readonly ElementsService:          ElementsService)
    {
        super(MessageService, MessageProcessingService);

        this.event = this.MessageService.messages.events.devicegroup;
        this.type  = this.MessageService.messages.msgTypesInfo.devicegroups;
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        this.cleanUpSubs();
    }


    // Override
    protected override update2(d: Element | ElementC | undefined): void
    {
        super.update2(DeviceGroup.get(d) as any); // will update this.el in parent

        if (this.el instanceof DeviceGroup) {
            const dg: DeviceGroup = this.el as DeviceGroup;
            // Check devices and update register with both MessageProcessingService and server to receive updates

            if (Array.isArray(dg.deviceIds) && dg.deviceIds.length > 0) {
                this.cleanUpSubs();
                const obs: Observable<any> | undefined = this.MessageProcessingService.getObsReg$(
                    this.MessageService.messages.events.device,
                    dg.deviceIds
                ); // MessageProcessingService.getObsReg$
                if (obs instanceof Observable) {
                    if (! (this._subs instanceof Subscription)) this._subs = new Subscription();
                    this._subs?.add(obs.subscribe((d: any) => {
                        // Try and update
                        if (d[this.MessageService.messages.msgTypesAttributes.message] === this.MessageService.messages.msgTypesInfo.disconnect) {
                            const dev: DeviceC = dg.deviceList.find((dv: DeviceC): boolean => dv.id === d[this.MessageService.messages.msgTypesAttributes.id])
                            if (dev instanceof DeviceC) dev.status = ElementStatus.Offline;
                            dg.update(); // need to udate
                        }
                        else dg.update(DeviceHelper.get(d)); 
                        this._updated$.next(this.el);
                    }) ); // add
                }
            }
            else {
                // Remove all registrations
                this.cleanUpSubs();
            }
        }
    }


    //
    // Private methods
    //
    private cleanUpSubs()
    {
        if (this._subs instanceof Subscription) {
            this._subs.unsubscribe();
            this._subs = undefined;
        }
    }
}