import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild 
}                         from '@angular/core';
import {
    SelectionChange,
    SelectionModel
}                         from "@angular/cdk/collections";
import {
    MatPaginator,
    PageEvent,

    MatSort,
    Sort,

    MatRow,
    MatTableDataSource
}                         from '@Material/';

import { BaseComponent }  from '@Base/';


export type ListDataAttributes = {
    name:   string,
    alias?: string,
    value?: string,
    class?: string
} // ListDataAttributes
// Type check
function isListDataAttributes(d: any): d is ListDataAttributes {
    return !! d.name && d.value;
    //["apple", "banana", "grape"].indexOf(fruit) !== -1;
}


@Component({
    selector:        'qp-list-data',
    templateUrl:     'list-data.component.html',
    styleUrls:       ['list-data.component.css']
})
export class ListDataComponent extends BaseComponent
{
    public  static readonly  stateAttr:              string                       = 'state';
    public  static readonly  statusAttr:             string                       = 'stateI';
    private static readonly  _trackByAttr:           string                       = 'id';

    private static readonly _pageSizeDefault:        number                       = 25;
    private static readonly _pageSizeOptionsDefault: number[]                     = [5, 10, 25, 50, 100, 250, 500];

    private           _attrs:                        ListDataAttributes[]         = [];
    private           _checked:                      any[];
    private           _data:                         ListDataAttributes[] | any[] = [];
    private           _dataQuantity:                 number;//                  = 0;
    private           _dataSrc:                      MatTableDataSource<any>;
    private           _initialised:                  boolean                      = false;
    private           _firstData:                    boolean                      = false;
    private           _paginator:                    MatPaginator;
    private           _sort:                         MatSort;
    private           _timeout:                      number;
    public            search:                        string                       = "";
    public  readonly  selectAttr:                    string                       = 'select';

    public            selectionModel:                SelectionModel<MatRow>;

 
    @Input()
    public            paginatorEnabled:    boolean                                = true;

    @Input()
    public            pageSizeDefault:     number                                 = ListDataComponent._pageSizeDefault;
    
    @Input()
    public            searchEnabled:       boolean                                = false;

    @Input()
    public            selectEnabled:       boolean                                = false;

    @Input()
    public            stateEnabled:        boolean                                = true;

    @Input()
    public            textCentre:          boolean                                = true;


    @Output()
    public readonly   filterChanged:       EventEmitter<string>                   = new EventEmitter();

    @Output()
    public readonly   pageChanged:         EventEmitter<PageEvent>                = new EventEmitter();

    @Output()
    public readonly   selected:            EventEmitter<MatRow>                   = new EventEmitter();

    @Output()
    public readonly   selectedM:           EventEmitter<MatRow[]>                 = new EventEmitter();

    @Output()
    public readonly   sortChanged:         EventEmitter<Sort>                     = new EventEmitter();


    constructor(private readonly cd: ChangeDetectorRef)
    {
        super();
    }


    // Override
    public override ngOnInit()
    {
        // Prevent parent from calling this.initialise() in this method
    }


    // Override
    public override ngAfterViewInit()
    {
        super.ngAfterViewInit();

        this._dataSrc = new MatTableDataSource();
        this.selectionModel = new SelectionModel<MatRow>(
            true,
            [],
            true,
            (o1: MatRow, o2: MatRow) => (o1 as any).id === (o2 as any).id
        ); // selectionModel

        this.sub = this.selectionModel.changed.subscribe((change: SelectionChange<MatRow>) => {
            console.error(this.selectionModel.selected)
            this.selectedM.emit(this.selectionModel.selected);
        }); // subscribe

        return this.initialise(); // do in ngAfterViewInit(); table *much* slower if this is called in ngOnInit()
    }


    //
    // Getters and setters
    //
    public get attrsI(): ListDataAttributes[]
    {
        return this._attrs;
    }


    @Input()
    public set attrs(d: ListDataAttributes[] | string[])
    {
        // console.debug("Attributes received");

        const attrsN: ListDataAttributes[] = [];
        if (d && Array.isArray(d)) d.forEach((a) => {
            attrsN.push((typeof a === "string") ? {name: a, alias: a} : a);
        }); // forEach

        this._attrs = attrsN;
    }


    public get attrsSrc(): ListDataAttributes[]
    {
        return (this.attrsI?.length)
            ? this.attrsI

            : (this.data?.length
                ? Object.keys(this.data[0]).map((a: string) => ({name: a, alias: a}))
                : []
            );
    }


    @Input()
    public get checked(): any[]
    {
        return this._checked;
    }

    public set checked(d: any[])
    {
        this._checked = d; // Need to wairt for data before applying

        // this.updateDisplay();
    }


    @Input()
    public get data(): ListDataAttributes[] | any[]
    {
        return this._data;
    }

    public set data(d: ListDataAttributes[] | any[])
    {
        // console.log("Data received");

        // console.error(this.attrs)
        // Convert generic object to ListDataAttrributes
        if (Array.isArray(d)) {
            const obj = d[0];
            if (obj && ! isListDataAttributes(obj)) {
                const a: ListDataAttributes[] = [];
                Object.keys(obj).forEach((k: string) => {
                    a.push({
                        name:  k,
                        value: obj[k]
                    }); // push()
                }) // Object.keys()
                // this._data = a;
                // console.error(a);
            }
            
            
            // [TBD]
            this._data = d;
        }
        else this._data = d;

        this.updateDisplay();
    }


    @Input()
    public get dataQuantity(): number
    {
        return this._dataQuantity;
    }

    public set dataQuantity(d: number)
    {
        this._dataQuantity = d;
        this.updateDisplay();
    }


    public get dataSrc(): MatTableDataSource<any>
    {
        return this._dataSrc;
    }


    public get displayedColumns(): (string | undefined)[]
    {
        const cols = this.attrsSrc.map((o => o?.alias));
        if (this.selectEnabled) cols.unshift(this.selectAttr);
        return cols;
    }


    private get initialised(): boolean
    {
        return this._initialised;
    }

    private set initialised(d: boolean)
    {
        this._initialised = d;
    }


    public get pageSizeOptions(): number[]
    {
        return Array.from(
            new Set(this.pageSizeDefault
                ? ListDataComponent._pageSizeOptionsDefault.concat(this.pageSizeDefault)
                : ListDataComponent._pageSizeOptionsDefault
            ) // Set()
            ).sort(); // Array.from()
    }


    @ViewChild(MatPaginator, { static: false })
    public get paginator(): MatPaginator
    {
        return this._paginator;
    }

    public set paginator(d: MatPaginator)
    {
        this._paginator = d;
        this.updateDisplay();
    }


    @ViewChild(MatSort, { static: false })
    public get sort(): MatSort
    {
        return this._sort;
    }

    public set sort(d: MatSort)
    {
        this._sort = d;
        this.updateDisplay();
    }


    public get stateAttrG(): string
    {
        return ListDataComponent.stateAttr;
    }


    public get statusAttrG(): string
    {
        return ListDataComponent.statusAttr;
    }


    //
    // Public functions
    //
    public filterChange(e: any): void
    {
        const f: string = (e && e.target) ? e.target.value : undefined;
        this.dataSrc.filter = (typeof f === 'string' && (this.search = f) ? f.trim().toLowerCase() : "");
        console.error(f)
        // console.log(this.dataSrc?.filteredData)
        this.filterChanged.emit(f);
        // this._firstData = false;
    }

    
    public onSelectionRowChange(row: any)
    {
        this.selectionModel.toggle(row);
    }


    public pageChange(e: PageEvent): void
    {
        this.pageChanged.emit(e);
        this._firstData = false;
    }


    public select(row: MatRow): void
    {
        this.selected.emit(row);
    }


    public sortChange(e: Sort)
    {
        this.sortChanged.emit(e);
        this._firstData = false;
    }


    public trackBy(index: any, item: any): any
    {
        return (item && ListDataComponent._trackByAttr in item)
            ? item[ListDataComponent._trackByAttr]
            : undefined;
    }


    //
    // Protected functions
    //

    // Override
    protected override cleanUp(): void
    {
        if (this._timeout) clearTimeout(this._timeout);
        this._timeout = 0;
    }


    // Override
    protected override initialise(): void
    {
        // console.debug("Initialising ListData component");

        this.initialised = true;
        this.selectionModel.clear();
        this.updateDisplay();

        // console.debug("Initialised ListData component");
    }
    

    //
    // Private functions
    //
    private map(d: any[]): any[]
    {
        return Array.isArray(d)
            ? d.map((d2: ListDataAttributes[]) => {
                const e: { [key: string]: any } = {};

                if (Array.isArray(d)) {
                    d2.forEach((d3: ListDataAttributes) => {
                        if (isListDataAttributes(d3)) {
                            if (d3 && typeof d3.name === "string") {
                                e[d3.name] = d3.value;
                                if (! e['class']) e['class'] = d3.class;
                            }
                        }
                    }); //forEach
                    // console.error(e)
                }

                return e;
            }) // map()

            : [];
    }


    private updateDisplay(data: any[] = this._data): void
    {
        // const x: PageEvent = new PageEvent();
        // x.length = 0;
        // x.pageIndex = 0;
        // x.pageSize= 20;
        // x.previousPageIndex = -1;
        // console.debug(x);

        // Only do if paginator and sort set otherwise wait for their events to come in
        if (this.sort && this.initialised && ! this._timeout) {
            if (Array.isArray(data)) {
                this.dataSrc.sort = this.sort;
                if (this.dataQuantity === data.length) {
                    console.debug("Setting paginator to data source: " + this.dataQuantity + ", " + data.length);
                    this.dataSrc.paginator = this.paginator // don't do with server pagination, breaks total
                }

                this.dataSrc.data = (Array.isArray(data[0]))
                    ? this.map(data)
                    : data;
                if (this.cd?.detectChanges instanceof Function) this.cd.detectChanges();

                if (! this._firstData) {
                    if (this.selectEnabled) {
                        // this.selectionModel.clear();
                        if (Array.isArray(this.checked)) this.checked.forEach(e => {
                            // Find element in data source
                            const o: any = this.dataSrc.data.find(e2 => e2?.id?.toLowerCase() == e?.id?.toLowerCase());
                            if (o) this.selectionModel.select(o);
                            console.error(this.selectionModel.selected)
                        }); // forEach
                    }

                    this._firstData = true;
                }
            }
        }
    }
}