<!-- <qp-title     [icon]="icon" [title]=title [value]=data.length></qp-title>
<qp-list-data [attrs]=attrs [data]=data [stateEnabled]=true (selected)="select($event)" [paginatorEnabled]=false></qp-list-data> -->

<qp-list
    [attrs]="attrs"
    [vals]="data"
    [title]="showTitle ? title : ''"
    [service]="data ? undefined : service"
    [order]="attrOrder"
    [checked]="devices"
    [searchEnabled]="searchEnabled"
    [selectEnabled]="selectEnabled"
    
    (selected)="select($event)"
    (selectedM)="selectM($event)">
    <!-- vals]="vals" [title]="title" [order]="attrOrder" [orderDesc]="attrOrderDesc" [pageSize]="10" (selected)="select($event)"></qp-list> -->
</qp-list>