import {
    Component,
    EventEmitter
}                                 from '@angular/core';
import {
    ActivatedRoute,
    ParamMap
}                                 from '@angular/router';
import { Subscription }           from 'rxjs';

import { BaseComponent }          from '@Base/';
import { DevicesComponent }       from '@Devices/devices.component';
import {
    DialogService,
    MatDialogRef
}                                 from '@Misc/Services/';
import { MessageService }         from '@Messaging/';
import { 
    // ElementService,
    ElementsService,
    ProgressSpinnerDialogService
}                                 from '@Misc/Services';
import {
    DeviceGroup,
    Element,
    ElementCommon as ElementC
}                                 from '@ObjElements/';

import { DeviceGroupService }     from './device-group.service';


enum ButtonType {
    Add,
    Cancel,
    Edit,
    Play,
    Remove,
    Stop
}; // enum


@Component({
    selector:     'qp-devicegroup',
    templateUrl:  'device-group.component.html',
    styleUrls:   ['device-group.component.css'],
    providers:   [ DeviceGroupService, ElementsService ] // scope services to this component only; need to this here for ElementsService
})
export class DeviceGroupComponent extends BaseComponent
{
    public readonly name:       string                  = "Device Group";

    private         _dialogRef: MatDialogRef<any>;
    private         _id:        number                  = -1;
    public          data:       DeviceGroup | undefined;
    public          mapEnable:  boolean                 = true;
    public          selected:   any[];
    public          tps:        any[];


    constructor(private readonly ActRoute:                     ActivatedRoute,
                private readonly DeviceGroupService:           DeviceGroupService,
                private readonly DialogService:                DialogService,

                // private readonly ElementService:               ElementService,
                private readonly MessageService:               MessageService,
                private readonly ProgressSpinnerDialogService: ProgressSpinnerDialogService)
    {
        super();

        // this.ElementService.srv  = DeviceGroupsService;
        // this.ElementService.event = this.MessageService.messages.events.devicegroup;
        // this.ElementService.type  = this.MessageService.messages.msgTypesInfo.devicegroups;
    }


    public get ButtonType(): typeof ButtonType
    {
        return ButtonType;
    }


    //
    // Public methods
    //
    // public button(type?: ButtonType): void
    // {
    //     let cmd: string | undefined = undefined;
    //     switch (type) {
    //         case ButtonType.Play:
    //             cmd = this.MessageService.messages.msgTypesAction.start;
    //             break;

    //         case ButtonType.Stop:
    //             cmd = this.MessageService.messages.msgTypesAction.stop;
    //             break;

    //         default:
    //             // Do nothing
    //     } // switch

    //     if (cmd) {
    //         // Will trigger messge to server
    //         this.DeviceGroupService.set(
    //             cmd,
    //             cmd === this.MessageService.messages.msgTypesAction.stop
    //                 ? this.data?.instanceid
    //                 : this._id
    //         ); // sendMsgSet
    //     }
    // }


    public onClick(button: ButtonType, event: MouseEvent): void
    {
        let cmd: string | undefined = undefined;
        switch (button) {
            case ButtonType.Add:
            case ButtonType.Edit:
                if (this.DialogService) {
                    const ref: MatDialogRef<any> = this.DialogService.showDialog(DevicesComponent, undefined, undefined, this.data?.deviceList);
                    if (ref instanceof MatDialogRef) {
                        let data: any;
                        this.sub = ref.afterOpened().subscribe(() => {
                            const component: any = ref.componentInstance?.compRef?.instance;
                            if (component) {
                                if (component.setDevices instanceof Function) {
                                    component.searchEnabled = true;
                                    component.selectEnabled = true;
                                    component.setDevices(this.data?.deviceList);
                                }
                                
                                if (component.selectedM instanceof EventEmitter) {
                                    this.sub = component.selectedM.subscribe((d: any[]) => {
                                        console.error(d)
                                        data = d;
                                    }); // subscribe
                                }
                            }
                        }); // subscribe

                        this.sub = ref.afterClosed().subscribe((d: any) => {
                            if (Array.isArray(data)) {
                                console.error(data)
                                this.send(
                                    this.MessageService.messages.msgTypesAction.update,
                                    {
                                        [this.MessageService.messages.msgTypesInfo.device]: data.map(d2 => d2?.id)
                                    }
                                ); // send
                            }
                        }); // subscribe
                    }
                }
            break;

            case ButtonType.Remove:
                if (Array.isArray(this.selected)) {
                    console.error(this.selected)
                    this.send(
                        this.MessageService.messages.msgTypesAction.update,
                        {
                            [this.MessageService.messages.msgTypesInfo.device]: this.selected.map(d2 => d2?.id)
                        }
                    ); // send
                }
            break;


            case ButtonType.Cancel:
                // this.Location.back();
            break;


            case ButtonType.Play:
                cmd = this.MessageService.messages.msgTypesAction.start;
            break;

            case ButtonType.Stop:
                cmd = this.MessageService.messages.msgTypesAction.stop;
            break;

            default:
                // Do nothing
        } // switch

        // Will trigger messge to server
        this.send(
            cmd,
            undefined,
            cmd === this.MessageService.messages.msgTypesAction.stop
                ? this.data?.instanceid
                : this._id
        ); // sendMsgSet
    }


    public refresh(id?: number): void
    {
        const idL: number = id ? id : this._id;
        if (idL >= 0) this.DeviceGroupService.id = idL; // triggers data look-up
    }


    public select(d: any): any
    {
        console.error(d)
    }


    public selectM(rows: any[]): void
    {
        this.selected = rows;
    }


    //
    // Protected functions
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising DeviceGroup component");
        
        this.ProgressSpinnerDialogService.show(true);
        this.sub = this.ActRoute.paramMap.subscribe((params: ParamMap): void => {
            // this.sub = this.ElementService.added.subscribe((data: Element | undefined): void => {
            //     this.data = DeviceGroup.get(data);
            //     this.ProgressSpinnerDialogService.clear();
            // }); // subscribe()

            const s: string | null = params?.get("id");
            if (! isNaN(Number(s)) ) this.refresh(this._id = Number(s));

            this.sub = this.DeviceGroupService.updated$.subscribe((data: Element | ElementC | undefined): void => {
                this.data = DeviceGroup.get(data);
                // console.error(this.data)
                this.ProgressSpinnerDialogService.clear();
            }); // subscribe()

        }); // subscribe()
    }


    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        if (this._dialogRef instanceof MatDialogRef) this._dialogRef.close();

        this.DeviceGroupService.id = undefined; // clear service
    }


    //
    // Private methods
    //
    private send(cmd: string | undefined, attr?: any, d: any | number = this._id): Subscription | undefined
    {
        return cmd
            // Will trigger messge to server
            ? this.DeviceGroupService.set(cmd, d, attr)

            : undefined;
    }
}