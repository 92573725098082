import { Routes }                   from '@angular/router';

import { AuthenticationGuard }      from '@Login/';
// import { 
//     notificationsRoutes,
//     NotificationsContainerComponent 
// }                                    from '@Notifications/';

// import {
//     DeviceComponent,
//     deviceRoutes
// }                                    from './device/';
import {
    DeviceGroupComponent,
    DeviceGroupAddComponent
}                                   from './devicegroup/';
import { DeviceGroupsComponent }    from './device-groups.component';
// import { DevicesContainerComponent } from './devices-container.component';

import { DeviceGroupsListComponent} from './device-groups-list.component';

import { routeNames }               from '../app.routing.names';


export const devicegroupsRoutes: Routes = [
    {
        path:        '',
        // component:   DeviceGroupsListComponent, // list
        component:   DeviceGroupsComponent, // list
        data:        { animation: routeNames.devicegroups },
        canActivate: [ AuthenticationGuard ]
        //outlet:      DevicesContainerComponent.outlet
    },

    {
        path:        'add',
        // component:   DeviceGroupsListComponent, // list
        component:   DeviceGroupAddComponent, // list
        // data:        { animation: routeNames.devicegroups },
        canActivate: [ AuthenticationGuard ]
        //outlet:      DevicesContainerComponent.outlet
    },

    {
        path:        ':id',
        component:   DeviceGroupComponent,  // individual
        data:        { animation: routeNames.devicegroups },
        canActivate: [ AuthenticationGuard ],
        //outlet:      DevicesContainerComponent.outlet,
        // children:    deviceRoutes
    // },


    // {
    //     path:        ':id/' + routeNames.notifications,
    //     component:   NotificationsContainerComponent,
    //     data:        { animation: routeNames.notifications },
    //     canActivate: [ AuthenticationGuard ],
    //     //outlet:      NotificationsContainerComponent.outlet,//'primary',//DevicesContainerComponent.outlet, // essential
    //     children:    notificationsRoutes
    }
];